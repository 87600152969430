import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Delhi Public School, Rohini",
  address: "Rohini, Delhi",
  client: "DPS Society, New Delhi",
  area: "12,000 Sq. Ft.",
  project: "Administrative Block, Delhi Public School, Rohini",
  category: "Institutions | Interior | School",
  status: "Completed",
  backlink: "/projects/institutions/",
};

export default class Projectdpsrohini extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Institutional Projects | Delhi Public School, Rohini"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore the beautiful, modern and highly functional interiors completed for the administrative block of a leading school in Delhi. "
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
